import React from "react"
import { Link } from "gatsby"

import { FaArrowRight } from "react-icons/fa"

import styles from "./index.module.scss"

const BlogPostCard = ({ image, title, description, link }) => {
  return (
    <div className={styles.blogPostCard}>
      <div className={styles.image}>{image}</div>
      <div className={styles.postInfo}>
        <h3>{title}</h3>
        <p>{description}</p>
        <Link className={styles.link} to={link}>
          Ler Notícia
          <FaArrowRight />
        </Link>
      </div>
    </div>
  )
}

export default BlogPostCard
