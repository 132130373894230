import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import classNames from "classnames"

import styles from "../styles/index.module.scss"

import Layout from "../components/Layout"
import Button from "../components/Button"
import BlogPostCard from "../components/BlogPostCard"

import LogoElement from "../assets/home/logo_element.svg"
import CultureIcon from "../assets/home/culture.svg"
import BigCultureIcon from "../assets/home/culture_big.svg"
import PatrimonyIcon from "../assets/home/patrimony.svg"
import NatureIcon from "../assets/home/nature.svg"
import BigNatureIcon from "../assets/home/nature_big.svg"
import EduationIcon from "../assets/home/education.svg"

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      getParishSectionImage: file(relativePath: { eq: "home/touro.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      getBlogPostCardImage: file(
        relativePath: { eq: "home/rota_caldeirao.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      getRotaCaldeiraoImage: file(
        relativePath: { eq: "home/rota_caldeirao.png" }
      ) {
        id
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
      getLatestBlogPosts: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(blog-posts)/.*\\\\.md$/" } }
        limit: 3
      ) {
        edges {
          node {
            frontmatter {
              title
              date
            }
            localImage {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  const breakPoints = useBreakpoint()

  const parishSectionDescriptionTextMobileClass = classNames({
    [styles.sectionDescriptionTextMobile]: true,
    [styles.parishSectionDescriptionTextMobile]: breakPoints.header,
  })

  const rotaCaldeiraoSectionDescriptionImageWrapperClass = classNames({
    [styles.sectionDescriptionImageWrapper]: true,
    [styles.rotaCaldeiraoSectionDescriptionImageWrapper]: breakPoints.header,
  })

  const traditionsSectionDescriptionWrapperClass = classNames({
    [styles.sectionDescriptionWrapper]: true,
    [styles.traditionsSectionDescriptionWrapper]: breakPoints.header,
  })

  const renderLatestBlogPosts = posts => {
    return posts.map((item, index) => {
      const { slug } = item.node.fields
      const { title, date } = item.node.frontmatter
      const { localImage } = item.node
      return (
        <div className={styles.post} key={`${title}-${index}`}>
          <BlogPostCard
            image={
              <Img
                fluid={localImage.childImageSharp.fluid}
                alt={title}
                style={{ height: "100%", width: "100%" }}
                imgStyle={{ objectFit: "cover" }}
              />
            }
            title={title}
            description={new Date(date).toLocaleString("pt-PT", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
            link={`/${slug}`}
          />
        </div>
      )
    })
  }

  return (
    <Layout>
      <section className={styles.parish}>
        {!breakPoints.header && (
          <>
            <div className={styles.sectionDescriptionWrapper}>
              <div className={styles.sectionDescription}>
                <h1>
                  Touro da boa sorte, toda a Beira num território a descobrir
                </h1>
                <p>
                  Touro é aldeia e sede de freguesia, assente no planalto da
                  serra da Nave, atravessada pelo rio Côvo, correnteza de
                  costumes e hábitos ancestrais.
                </p>
                <Link to="/freguesia">
                  <Button>A Freguesia</Button>
                </Link>
              </div>
              <div className={styles.sectionDescriptionIconWrapper}>
                <LogoElement />
              </div>
            </div>
            <div
              className={classNames(
                styles.sectionDescriptionImageWrapper,
                styles.sectionDescriptionImageWrapperCover
              )}
            >
              <Img
                fluid={data.getParishSectionImage.childImageSharp.fluid}
                alt="Freguesia do Touro"
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </>
        )}
        {breakPoints.header && (
          <>
            <div
              className={classNames(
                styles.sectionDescriptionImageWrapper,
                styles.parishSectionDescriptionImageWrapperMobile
              )}
            >
              <Img
                fluid={data.getParishSectionImage.childImageSharp.fluid}
                alt="Freguesia do Touro"
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
              <h1>
                Touro da boa sorte, toda a Beira num território a descobrir
              </h1>
            </div>
            <div className={parishSectionDescriptionTextMobileClass}>
              <div
                className={
                  styles.parishSectionDescriptionTextInnerWrapperMobile
                }
              >
                <p>
                  Touro é aldeia e sede de freguesia, assente no planalto da
                  serra da Nave, atravessada pelo rio Côvo, correnteza de
                  costumes e hábitos ancestrais.
                </p>
                <Link to="/freguesia">
                  <Button>A Freguesia</Button>
                </Link>
              </div>
            </div>
          </>
        )}
      </section>
      <section className={styles.fundamentals}>
        <h2>Freguesia do Touro em movimento</h2>
        <p>
          A aldeia, encimada pela capela de Santo António, cruz atravessada pela
          medieval rua Central, paralela ao rio, descendo até Encosta, chegando
          à ponte do Covo e, já à saída, às Lameiras, que simbolizam o fértil
          dos campos, em terra de lavoura e granito. “Terra de principio”, é
          campo e montanha, de carvalhos, trigo e cevada.
        </p>
        <div className={styles.fundamentalsIcons}>
          <div className={styles.fundamentalsIcon}>
            <div>
              <CultureIcon />
            </div>
            <p>Cultura</p>
          </div>
          <div className={styles.fundamentalsIcon}>
            <div>
              <NatureIcon />
            </div>
            <p>Natureza</p>
          </div>
          <div className={styles.fundamentalsIcon}>
            <div>
              <EduationIcon />
            </div>
            <p>Educação</p>
          </div>
          <div className={styles.fundamentalsIcon}>
            <div>
              <PatrimonyIcon />
            </div>
            <p>Património</p>
          </div>
        </div>
      </section>
      <section className={styles.blog}>
        <h2>Notícias</h2>
        <p>Acompanhe, aqui, alguns dos últimos acontecimentos da freguesia</p>
        <div className={styles.postsWrapper}>
          {renderLatestBlogPosts(data.getLatestBlogPosts.edges)}
        </div>
        <Link to="/noticias">
          <Button>Ver Notícias</Button>
        </Link>
      </section>
      <section className={styles.rotaCaldeirao}>
        <div className={rotaCaldeiraoSectionDescriptionImageWrapperClass}>
          <Img
            fluid={data.getRotaCaldeiraoImage.childImageSharp.fluid}
            alt="Paisagem da Rota do Caldeirão"
          />
        </div>
        <div className={styles.rotaCaldeiraoSectionDescriptionWrapper}>
          <div className={styles.sectionDescription}>
            <h1>Rota do Caldeirão</h1>
            <p>
              A Rota do Caldeirão é caminho antigo, de moleiros, levados a
              espojar cereal, trigo e milho, com eiras e palhais.
            </p>
            <Link to="/rota-caldeirao">
              <Button>Saber Mais</Button>
            </Link>
          </div>
          <div className={styles.sectionDescriptionIconWrapper}>
            <BigNatureIcon />
          </div>
        </div>
      </section>
      <section className={styles.traditions}>
        <div className={traditionsSectionDescriptionWrapperClass}>
          <div className={styles.sectionDescription}>
            <h1>Os Nossos Costumes</h1>
            <p>
              Segada, Malhada, Carnaval e o grande certame que é a Feira do
              Fumeiro, são alguns dos bons costumes deste retalho da Beira Alta.
            </p>
            <Link to="/costumes">
              <Button>Saber Mais</Button>
            </Link>
          </div>
        </div>
        {!breakPoints.header && (
          <div
            className={classNames(
              styles.sectionDescriptionImageWrapper,
              styles.sectionDescriptionImageWrapperCentered
            )}
          >
            <div className={styles.traditionsImage}>
              <BigCultureIcon />
            </div>
          </div>
        )}
      </section>
    </Layout>
  )
}
